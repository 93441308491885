import BuyArea from '../../components/buy-area';

import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';

import { Sentence as SentenceType } from '../../state/preview.machine';

type Props = {
  sentences: SentenceType[];
  selectedSuggestionId?: string;
  onSuggestionClick: (suggestionId: string) => () => void;
};

const EditorWorkspace: React.FC<Props> = (props) => {
  return (
    <>
      <s.asideLeft />
      <s.editor>
        <Preview {...props} />
        <WordLimitFooter />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner>
          <SuggestionsBrowser />
          <BuyArea />
        </s.asideRightInner>
      </s.asideRight>
    </>
  );
};

export default EditorWorkspace;
